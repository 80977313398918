import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-db48bcbc")
const _hoisted_1 = { class: "card-header" }
const _hoisted_2 = { class: "px-4 py-2" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "text-secondary text-sm" }
const _hoisted_7 = { class: "flex flex-col flex-grow text-right" }
const _hoisted_8 = { class: "text-secondary text-sm" }
_popScopeId()

import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';

import { POOL_MIGRATIONS_MAP } from '@/components/forms/pool_actions/MigrateForm/constants';
import { PoolMigrationType } from '@/components/forms/pool_actions/MigrateForm/types';
import useStaking from '@/composables/staking/useStaking';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { usePool } from '@/composables/usePool';
import useTokens from '@/composables/useTokens';
import { MIN_FIAT_VALUE_POOL_MIGRATION } from '@/constants/pools';
import { bnum } from '@/lib/utils';
import PoolCalculator from '@/services/pool/calculator/calculator.sevice';
import { Pool } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

import PoolActionsCard from './PoolActionsCard.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  missingPrices: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    missingPrices: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  missingPrices: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { tokens, balances, balanceFor, getTokens } = useTokens();
const { fNum2, toFiat } = useNumbers();
const { isWalletReady } = useWeb3();
const { isStableLikePool, isStablePhantomPool, isMigratablePool } = usePool(
  toRef(props, 'pool')
);
const {
  userData: { stakedSharesForProvidedPool }
} = useStaking();
const router = useRouter();

/**
 * SERVICES
 */
const poolCalculator = new PoolCalculator(
  toRef(props, 'pool'),
  tokens,
  balances,
  'exit',
  ref(false)
);

/**
 * COMPUTED
 */
const bptBalance = computed((): string => balanceFor(props.pool.address));

const poolTokens = computed(() =>
  Object.values(getTokens(props.pool.tokensList))
);

const propTokenAmounts = computed((): string[] => {
  const { receive } = poolCalculator.propAmountsGiven(
    bnum(bptBalance.value)
      .plus(stakedSharesForProvidedPool.value)
      .toString(),
    0,
    'send'
  );

  if (isStablePhantomPool.value) {
    // Return linear pool's main token balance using the price rate.
    // mainTokenBalance = linearPoolBPT * priceRate
    return props.pool.tokensList.map((address, i) => {
      if (!props.pool?.onchain?.linearPools) return '0';

      const priceRate = props.pool.onchain.linearPools[address].priceRate;

      return bnum(receive[i])
        .times(priceRate)
        .toString();
    });
  }

  return receive;
});

const tokenAddresses = computed((): string[] => {
  if (isStablePhantomPool.value) {
    // We're using mainToken balances for StablePhantom pools
    // so return mainTokens here so that fiat values are correct.
    return props.pool.mainTokens || [];
  }
  return props.pool.tokensList;
});

const fiatValue = computed(() =>
  tokenAddresses.value
    .map((address, i) => toFiat(propTokenAmounts.value[i], address))
    .reduce((total, value) =>
      bnum(total)
        .plus(value)
        .toString()
    )
);

const showMigrateButton = computed(
  () =>
    bnum(bptBalance.value).gt(0) &&
    isMigratablePool(props.pool) &&
    // TODO: this is a temporary solution to allow only big holders to migrate due to gas costs.
    bnum(fiatValue.value).gt(MIN_FIAT_VALUE_POOL_MIGRATION)
);
/**
 * METHODS
 */
function weightLabelFor(address: string): string {
  if (!props.pool || !props.pool) return '-';
  const weight = props.pool?.onchain?.tokens?.[address]?.weight;
  return weight
    ? fNum2(weight, {
        style: 'percent',
        maximumFractionDigits: 0
      })
    : '-';
}

function fiatLabelFor(index: number, address: string): string {
  const fiatValue = toFiat(propTokenAmounts.value[index], address);
  return fNum2(fiatValue, FNumFormats.fiat);
}

function navigateToPoolMigration(pool: Pool) {
  router.push({
    name: 'migrate-pool',
    params: {
      from: pool.id,
      to: POOL_MIGRATIONS_MAP[PoolMigrationType.AAVE_BOOSTED_POOL].toPoolId
    },
    query: {
      returnRoute: 'pool',
      returnParams: JSON.stringify({ id: pool.id })
    }
  });
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    shadow: "2xl",
    noPad: "",
    class: "rounded-xl"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('poolTransfer.myPoolBalancesCard.title')), 1),
        _createElementVNode("h5", null, _toDisplayString(_unref(isWalletReady) ? _unref(fNum2)(_unref(fiatValue), _unref(FNumFormats).fiat) : '-'), 1)
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(PoolActionsCard, {
        pool: __props.pool,
        missingPrices: __props.missingPrices
      }, null, 8, ["pool", "missingPrices"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tokenAddresses), (address, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: address,
            class: "asset-row"
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BalAsset, {
                address: _unref(poolTokens)[index].address,
                size: 36,
                class: "mr-4"
              }, null, 8, ["address"]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, [
                  (!_unref(isStableLikePool))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(weightLabelFor(address)), 1))
                    : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_unref(poolTokens)[index].symbol), 1)
                ]),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(poolTokens)[index].name), 1)
              ])
            ]),
            _createElementVNode("span", _hoisted_7, [
              _createTextVNode(_toDisplayString(_unref(isWalletReady)
              ? _unref(fNum2)(_unref(propTokenAmounts)[index], _unref(FNumFormats).token)
              : '-') + " ", 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(isWalletReady) ? fiatLabelFor(index, address) : '-'), 1)
            ])
          ]))
        }), 128)),
        (_unref(showMigrateButton))
          ? (_openBlock(), _createBlock(_component_BalBtn, {
              key: 0,
              color: "blue",
              class: "mt-4",
              block: "",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (navigateToPoolMigration(props.pool)), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('migratePool.migrateToBoostedPool')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})