import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "ml-1 text-secondary text-xs" }
const _hoisted_3 = { class: "text-secondary text-xs ml-1" }

import { PoolType } from '@balancer-labs/sdk';
import { getAddress } from '@ethersproject/address';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { isStablePhantom } from '@/composables/usePool';
import useTokens from '@/composables/useTokens';
import { includesWstEth } from '@/lib/utils/balancer/lido';
import { PoolAPRs } from '@/services/pool/types';

/**
 * TYPES
 */
type Props = {
  yieldAPR: PoolAPRs['yield'];
  poolTokens: string[];
  poolType: PoolType;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    yieldAPR: { type: null, required: true },
    poolTokens: { type: Array, required: true },
    poolType: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  yieldAPR: PoolAPRs['yield'];
  poolTokens: string[];
  poolType: PoolType;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { getTokens } = useTokens();
const { fNum2 } = useNumbers();
const { t } = useI18n();

/**
 * COMPUTED
 */
const yieldAPRTokens = computed(() =>
  getTokens(Object.keys(props.yieldAPR.breakdown))
);

const hasMultiRewardTokens = computed(
  () => Object.keys(yieldAPRTokens.value).length > 1
);

const yieldAPRLabel = computed(() => {
  if (includesWstEth(props.poolTokens)) return t('yieldAprRewards.apr.steth');
  if (isStablePhantom(props.poolType)) return t('yieldAprRewards.apr.boosted');

  return '';
});

const yieldBreakdownItems = computed((): [string, string][] =>
  Object.entries(props.yieldAPR.breakdown)
);

return (_ctx: any,_cache: any) => {
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!

  return (_openBlock(), _createBlock(_component_BalBreakdown, {
    items: _unref(yieldBreakdownItems),
    hideItems: !_unref(hasMultiRewardTokens)
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createTextVNode(_toDisplayString(_unref(fNum2)(__props.yieldAPR.total, _unref(FNumFormats).percent)) + " ", 1),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(yieldAPRLabel)), 1)
      ])
    ]),
    _: 2
  }, [
    (_unref(hasMultiRewardTokens))
      ? {
          name: "item",
          fn: _withCtx(({ item: [address, amount] }) => [
            _createTextVNode(_toDisplayString(_unref(fNum2)(amount, _unref(FNumFormats).percent)) + " ", 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(yieldAPRTokens)[_unref(getAddress)(address)].symbol) + " " + _toDisplayString(_ctx.$t('apr')), 1)
          ])
        }
      : undefined
  ]), 1032, ["items", "hideItems"]))
}
}

})