import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-4 lg:mx-0 flex justify-between items-end border-b dark:border-gray-900 mb-6" }

import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import BalTabs from '@/components/_global/BalTabs/BalTabs.vue';
import { Pool } from '@/services/pool/types';

import LBPDetails from './details/LBPDetails.vue';
import TradeTransactions from './TradeTransactions/TradeTransactions.vue';
import { DetailsTab } from './types';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {

const props = __props


/**
 * COMPUTED
 */
const tabs = computed(() => [
  {
    value: DetailsTab.DETAILS,
    label: t('poolTransactions.tabs.lbpDetails')
  },
  {
    value: DetailsTab.SWAP,
    label: t('poolTransactions.tabs.trades')
  }
]);

/**
 * COMPOSABLES
 */
const { t } = useI18n();
const activeTab = ref(tabs.value[0].value);

/**
 * STATE
 */

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(BalTabs, {
          modelValue: activeTab.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (activeTab.value = $event)),
          tabs: _unref(tabs),
          "no-pad": "",
          class: "-mb-px"
        }, null, 8, ["modelValue", "tabs"])
      ])
    ]),
    (activeTab.value === _unref(DetailsTab).DETAILS)
      ? (_openBlock(), _createBlock(LBPDetails, {
          key: 0,
          pool: __props.pool,
          loading: __props.loading
        }, null, 8, ["pool", "loading"]))
      : (activeTab.value === _unref(DetailsTab).SWAP)
        ? (_openBlock(), _createBlock(TradeTransactions, {
            key: 1,
            pool: __props.pool,
            loading: __props.loading
          }, null, 8, ["pool", "loading"]))
        : _createCommentVNode("", true)
  ], 64))
}
}

})