import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3" }
const _hoisted_2 = { class: "justify-self-end" }
const _hoisted_3 = { class: "justify-self-end" }

import { formatUnits } from '@ethersproject/units';
import { computed } from 'vue';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type Props = {
  mainTokenAddress?: string;
  priceRate?: string;
  address: string;
  balance: string;
  share: string;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    mainTokenAddress: { type: String, required: false },
    priceRate: { type: String, required: false },
    address: { type: String, required: true },
    balance: { type: String, required: true },
    share: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  mainTokenAddress?: string;
  priceRate?: string;
  address: string;
  balance: string;
  share: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { getToken } = useTokens();
const { fNum2, toFiat } = useNumbers();
const { explorerLinks } = useWeb3();

/**
 * COMPUTED
 */
const token = computed(() => getToken(props.address));

const balance = computed(() => {
  const formattedBalance = formatUnits(props.balance, token.value.decimals);
  return props.share != null
    ? bnum(formattedBalance)
        .times(props.share)
        .toString()
    : formattedBalance;
});

const balanceLabel = computed(() => {
  if (props.priceRate && props.mainTokenAddress) {
    const equivMainTokenBalance = bnum(balance.value)
      .times(props.priceRate)
      .toString();

    return fNum2(equivMainTokenBalance, FNumFormats.token);
  }

  return fNum2(balance.value, FNumFormats.token);
});

const fiatLabel = computed(() => {
  if (props.priceRate && props.mainTokenAddress) {
    const equivMainTokenBalance = bnum(balance.value)
      .times(props.priceRate)
      .toString();

    const fiatValue = toFiat(equivMainTokenBalance, props.mainTokenAddress);
    return fNum2(fiatValue, FNumFormats.fiat);
  }

  let fiatValue = toFiat(balance.value, props.address);
  return fNum2(fiatValue, FNumFormats.fiat);
});

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_BalLink, {
        href: _unref(explorerLinks).addressLink(_unref(token).address),
        external: "",
        noStyle: "",
        class: "flex items-center"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BalAsset, {
            address: _unref(token).address,
            class: "mr-2"
          }, null, 8, ["address"]),
          _createTextVNode(" " + _toDisplayString(_unref(token).symbol) + " ", 1),
          _createVNode(_component_BalIcon, {
            name: "arrow-up-right",
            size: "sm",
            class: "ml-2 text-secondary hover:text-blue-500 transition-colors"
          })
        ]),
        _: 1
      }, 8, ["href"])
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(balanceLabel)), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(fiatLabel)), 1)
  ]))
}
}

})