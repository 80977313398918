import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

import { flatten } from 'lodash';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import usePoolSwapsQuery from '@/composables/queries/usePoolSwapsQuery';
import { poolId } from '@/constants/pools';
import { Pool } from '@/services/pool/types';

import Table from './Table.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {



/**
 * COMPOSABLES
 */
const route = useRoute();

/**
 * STATE
 */
const id = poolId as string;

/**
 * QUERIES
 */
const poolSwapsQuery = usePoolSwapsQuery(id);

/**
 * COMPUTED
 */
const poolSwaps = computed(() =>
  poolSwapsQuery.data.value
    ? flatten(poolSwapsQuery.data.value.pages.map(page => page.poolSwaps))
    : []
);
const isLoadingPoolSwaps = computed(() => poolSwapsQuery.isLoading.value);
const poolSwapsHasNextPage = computed(() => poolSwapsQuery.hasNextPage?.value);
const poolSwapsIsFetchingNextPage = computed(
  () => poolSwapsQuery.isFetchingNextPage?.value
);

/**
 * METHODS
 */
function loadMorePoolSwaps() {
  poolSwapsQuery.fetchNextPage.value();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", {
      textContent: _toDisplayString(_ctx.$t('poolTransactions.tabs.trades')),
      class: "px-4 lg:px-0 mb-5"
    }, null, 8, _hoisted_1),
    _createVNode(Table, {
      tokens: __props.pool ? __props.pool.tokensList : [],
      "pool-swaps": _unref(poolSwaps),
      "is-loading": __props.loading || _unref(isLoadingPoolSwaps),
      "is-loading-more": _unref(poolSwapsIsFetchingNextPage),
      "is-paginated": _unref(poolSwapsHasNextPage),
      onLoadMore: loadMorePoolSwaps,
      "no-results-label": _ctx.$t('poolTransactions.noResults.swaps')
    }, null, 8, ["tokens", "pool-swaps", "is-loading", "is-loading-more", "is-paginated", "no-results-label"])
  ], 64))
}
}

})