import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

type Props = {
  size?: 'sm' | 'md' | 'lg';
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    size: { type: String, required: false, default: 'sm' }
  } as unknown as undefined,
  setup(__props: {
  size?: 'sm' | 'md' | 'lg';
}) {

const props = __props


return (_ctx: any,_cache: any) => {
  const _component_BalChip = _resolveComponent("BalChip")!

  return (_openBlock(), _createBlock(_component_BalChip, _mergeProps(props, {
    color: "orange",
    class: "uppercase",
    outline: false
  }), {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('new')), 1)
    ]),
    _: 1
  }, 16))
}
}

})