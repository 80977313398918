import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { toRef } from 'vue';

import { usePool } from '@/composables/usePool';
import { Pool } from '@/services/pool/types';

import InvestmentTransactions from './InvestmentTransactions/InvestmentTransactions.vue';
import PoolDetailsCard from './PoolDetailsCard.vue';
import TradeTransactions from './TradeTransactions/TradeTransactions.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { isStablePhantomPool } = usePool(toRef(props, 'pool'));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PoolDetailsCard, {
    pool: __props.pool,
    loading: __props.loading
  }, null, 8, ["pool", "loading"]))
}
}

})