import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4" }
const _hoisted_2 = { class: "text-sm text-secondary font-medium mb-2 flex" }
const _hoisted_3 = { class: "text-xl font-medium truncate flex items-center" }

import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import APRTooltip from '@/components/tooltips/APRTooltip/APRTooltip.vue';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { totalAprLabel } from '@/composables/usePool';
import { APR_THRESHOLD } from '@/constants/pools';
import { Pool } from '@/services/pool/types';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading?: boolean;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading?: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { t } = useI18n();

/**
 * COMPUTED
 */
const aprLabel = computed((): string => {
  const poolAPRs = props.pool?.apr;
  if (!poolAPRs) return '0';

  return totalAprLabel(poolAPRs, props.pool.boost);
});

// const timer = (endDate: number) => {
//   const now = new Date().getTime()
//   const t = endDate - now;
//   const days = Math.floor(t / (1000 * 60 * 60 * 24));
//   const hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
//   const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
//   const seconds = Math.floor((t % (1000 * 60)) / 1000);
//   return `${days}:${hours}:${minutes}:${seconds}`
// }

const timeNow = ref(new Date());
const startBalanceMain = 23916519000000;
const startBalanceBase = 100;
const timer = computed((): string => {
  const endDate = Number(props.pool.weightUpdates![1].endTimestamp) * 1000;
  const now = timeNow.value.getTime();
  const t = endDate - now;
  const days = Math.floor(t / (1000 * 60 * 60 * 24));
  const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((t % (1000 * 60)) / 1000);
  return `${days}:${hours}:${minutes}:${seconds}`;
});
onMounted(() => {
  const stopCountdown = setInterval(() => {
    timeNow.value = new Date();
    // if (!this.countdown) clearInterval(stopCountdown)
  }, 1000);
});
const stats = computed(() => {
  if (!props.pool) return [];
  const endDate = Number(props.pool.weightUpdates![1].endTimestamp);
  const startDate = Number(props.pool.weightUpdates![0].startTimestamp);
  const duration = `${((endDate - startDate) / (60 * 60 * 24)).toFixed(
    0
  )} days`;
  const currentMain = Number(props.pool.tokens[1].balance);

  const currentBase = Number(props.pool.tokens[0].balance);
  const mainTokenReleased = startBalanceMain - currentMain;
  const mainTokenPercentage =
    ((mainTokenReleased / startBalanceMain) * 100).toFixed(0) + '%';
  const baseTokenAccured = currentBase - startBalanceBase;

  return [
    {
      id: 'poolValue',
      label: t('poolValue'),
      value: fNum2(props.pool.totalLiquidity, FNumFormats.fiat)
    },
    {
      id: 'volumeTime',
      label: t('volumeTime', ['24h']),
      value: fNum2(props.pool.volumeSnapshot || '0', FNumFormats.fiat)
    },
    {
      id: 'feesTime',
      label: t('feesTime', ['24h']),
      value: fNum2(props.pool.feesSnapshot || '0', FNumFormats.fiat)
    },
    {
      id: 'apr',
      label: 'APR',
      value:
        Number(props.pool?.apr?.total.unstaked || '0') * 100 > APR_THRESHOLD
          ? '-'
          : aprLabel.value
    },
    {
      id: 'duration',
      label: t('duration'),
      value: duration
    },
    {
      id: 'endsIn',
      label: t('endsIn'),
      value: timer.value
    },
    {
      id: 'mainTokenReleased',
      label: t('mainTokenReleased'),
      value: mainTokenPercentage
    },
    {
      id: 'baseTokenAccrued',
      label: t('baseTokenAccrued'),
      value: fNum2(baseTokenAccured)
    }
  ];
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (n) => {
          return _createVNode(_component_BalLoadingBlock, {
            key: n,
            class: "h-24"
          })
        }), 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_unref(stats), (stat, i) => {
          return (_openBlock(), _createBlock(_component_BalCard, { key: i }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(stat.label), 1),
                (stat.id === 'apr')
                  ? (_openBlock(), _createBlock(APRTooltip, {
                      key: 0,
                      pool: __props.pool
                    }, null, 8, ["pool"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_3, _toDisplayString(stat.value), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
  ]))
}
}

})