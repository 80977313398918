import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-1 text-secondary text-xs" }
const _hoisted_2 = { class: "text-secondary text-xs" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useNumbers, { FNumFormats } from '@/composables/useNumbers';

/**
 * TYPES
 */
type Props = {
  apr: string;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    apr: { type: String, required: true }
  } as unknown as undefined,
  setup(__props: {
  apr: string;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { t } = useI18n();

/**
 * COMPUTED
 */
const aprLabel = computed((): string => fNum2(props.apr, FNumFormats.percent));

const items = computed((): string[] => [
  t('tooltips.veBalApr.breakdown1'),
  t('tooltips.veBalApr.breakdown2')
]);

return (_ctx: any,_cache: any) => {
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!

  return (_openBlock(), _createBlock(_component_BalBreakdown, { items: _unref(items) }, {
    item: _withCtx(({ item }) => [
      _createElementVNode("div", _hoisted_2, _toDisplayString(item), 1)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(aprLabel)) + " ", 1),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('tooltips.veBalApr.title')), 1)
    ]),
    _: 1
  }, 8, ["items"]))
}
}

})